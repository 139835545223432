
<v-menu offset-y left transition="slide-y-transition">
  <template v-slot:activator="{ on }">
    <v-btn icon class="elevation-2" v-on="on">
      <v-badge
        color="success"
        dot
        bordered
        offset-x="10"
        offset-y="10"
      >
        <v-avatar size="40">
          <v-img v-if="user.authData && user.authData.avatarUrl" :src="user.authData.avatarUrl" />
          <v-img v-else src="/images/avatars/avatar1.svg"></v-img>
        </v-avatar>
      </v-badge>
    </v-btn>
  </template>

  <!-- user menu list -->
  <v-list
    dense
    nav
    color="components"
  >
    <template v-if="$isGrantedByToken('ROLE_ALLOWED_TO_SWITCH') && !$isGranted('ROLE_ALLOWED_TO_SWITCH')">
      <v-list-item @click="handleLogoutFromUserAccount">
        <v-list-item-icon>
          <v-icon small>mdi-logout-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('$menu.logoutFromUserAccount') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-1"></v-divider>
    </template>

    <template v-for="(item, index) in menu">
      <v-list-item
        v-if="$isGranted(item.perm)"
        :key="index"
        :to="item.routeName ? { name: item.routeName } : ''"
        :exact="item.exact"
        :disabled="item.disabled"
        link
      >
        <v-list-item-icon>
          <v-icon small :class="{ 'grey--text': item.disabled }">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.key ? $t(item.key) : item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider class="my-1"></v-divider>

    <v-list-item @click="handleLogout">
      <v-list-item-icon>
        <v-icon small>mdi-logout-variant</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('$menu.logout') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</v-menu>
