
<v-alert
  class="notification-alert"
  v-bind="$attrs"
  v-on="$listeners"
  :color="color"
  :dark="mode === themeMode.DARK"
  :icon="icon"
  :border="border"
  :prominent="prominent"
  :min-height="minHeight"
  :max-width="maxWidth"
>
  <slot></slot>
</v-alert>
