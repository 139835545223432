
<v-menu
  v-model="visible"
  offset-y
  offset-x
  offset-overflow
  left
  bottom
  attach="#app-bar"
  transition="slide-y-transition"
>
  <template v-slot:activator="{ on, attrs }">
    <v-badge
      :content="unreadNotifications"
      :value="unreadNotifications"
      :light="themeMode === MODE.LIGHT"
      :dark="themeMode === MODE.DARK"
      color="primary"
      offset-x="22"
      offset-y="22"
      overlap
    >
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        @click.stop="handleNotificationsBellClick"
      >
        <v-icon :color="NAVIGATION.NOTIFICATIONS_ICON_COLOR">mdi-bell-outline</v-icon>
      </v-btn>
    </v-badge>
  </template>

  <!-- notifications dropdown card -->
  <notifications-list :notifications="notifications" />
</v-menu>
