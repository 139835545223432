
<v-switch
  class="theme-mode-switcher"
  v-model="themeMode"
  :prepend-icon="icon"
  true-value="light"
  false-value="dark"
  :color="color"
  inset
  @change="handleThemeSwitch"
/>
