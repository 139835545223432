
<div class="d-flex flex-grow-1">
  <app-toolbar
    @click="handleHamburgerClick"
    :is-sidebar-collapsed="sidebar.collapsed"
  />

  <app-sidebar
    v-model="sidebar.collapsed"
    v-bind="sidebar"
    :menu-items="navItems"
  />

  <v-main>
    <v-layout class="fill-height pt-1 px-1 pb-6 pt-sm-2 px-sm-2 pt-md-3 px-md-3" fluid>
      <v-layout>
        <transition name="fade" mode="out-in" appear>
          <router-view :key="$route.fullPath"/>
        </transition>
      </v-layout>
    </v-layout>

    <app-footer inset/>
  </v-main>
  <customization-menu v-if="$isGranted('ROLE_ADMIN')" />
</div>
