
<v-card tile>
  <v-toolbar
    flat
    dark
    class="create-edit-invoice-dialog__toolbar px-1"
    color="primary"
  >
    <v-toolbar-title>{{ form.title }}</v-toolbar-title>
    <v-spacer/>
    <v-btn
      v-if="form.hasCloseButton"
      icon
      dark
      @click="handlePending"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-toolbar>
  <v-card-text class="pt-2">
    <div v-html="form.content"></div>
    <v-textarea :value="form.note" dense outlined v-if="form.hasTextArea" @input="value => handleFieldChange('note', value)"/>
  </v-card-text>
  <v-card-actions class="justify-end d-inline-flex flex-wrap card-actions" style="gap: 8px">
    <v-btn v-if="form.hasRejectButton" class="btn-fit" @click="handleRejectClick">
      {{ form.rejectText }}
    </v-btn>
    <v-btn
      class="btn-fit"
      color="primary"
      @click="handleSubmitClick"
    >
      {{ form.submitText }}
    </v-btn>
  </v-card-actions>
</v-card>
