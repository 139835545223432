
<div class="auth-layout layout-content ma-auto w-full px-1 px-md-2 px-lg-0">
  <v-img
    max-height="150"
    max-width="400"
    class="my-4 mx-auto"
    :src="logoImagePath"
  />
  <transition name="fade" mode="out-in" appear>
    <router-view :key="$route.fullPath"/>
  </transition>
</div>
