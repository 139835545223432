
<v-menu offset-y left transition="slide-y-transition">
  <template v-slot:activator="{ on }">
    <div class=" content-text-container cursor-pointer" v-on="on">
      <v-icon class="pr-1 pl-1" color="grey lighten-3">mdi-autorenew</v-icon>
      <span v-if="$vuetify.breakpoint.smAndUp" class="grey--text text--lighten-3 pr-2">{{ currentCompany.name }}</span>
    </div>
  </template>

  <!-- company menu list -->
  <v-list
    dense
    nav
    color="components"
  >
    <template v-for="(item, index) in myCompanies">
      <v-list-item
        :key="index"
        :disabled="item.id === user.currentCompanyId"
        @click="handleChangeCompanyClick(item.id)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.name}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</v-menu>
