import i18n from '@/plugins/vue-i18n';
import { initApp } from '@/api/init'
import { MODE as themeMode, THEME_COLORS } from '@/constants/theme'
import { updateLightModeSettings, updateDarkModeSettings } from '@/api/admin/app-settings';
import plLocale from 'vuetify/lib/locale/pl.js';
import plValidation from 'vee-validate/dist/locale/pl.json';

const init = async ({ commit }) => {
  const initData = await initApp();
  const {
    language,
    darkMode,
    lightMode,
    officeInformation,
  } = initData;

  const settings = Object.assign(
    {},
    { darkMode: { ...darkMode, success: THEME_COLORS.DARK_MODE.SUCCESS, warning: THEME_COLORS.DARK_MODE.WARNING, error: THEME_COLORS.DARK_MODE.ERROR }},
    { lightMode: {  ...lightMode, success: THEME_COLORS.LIGHT_MODE.SUCCESS, warning: THEME_COLORS.LIGHT_MODE.WARNING, error: THEME_COLORS.LIGHT_MODE.ERROR }},
    { officeInformation },
  )


  commit('SET_SETTINGS', settings);

  if (!language.locale || !language.messages) {
    throw new Error('Init data does not have language configuration.');
  }

  // let vuetifyTranslations = require(`vuetify/lib/locale/${language.locale}.js`);
  // let validationTranslations = require(`vee-validate/dist/locale/${language.locale}.json`);

  i18n.setLocaleMessage(language.locale, Object.assign(
    {},
    language.messages,
    { $vuetify: { ...plLocale } },
    { validations: { messages: { ...plValidation.messages, ...language.messages.$validationCustomRulesMessages } } }
  ));
  i18n.locale = language.locale;

  commit('SET_LANGUAGE', language);
}

const saveModeSettings = async ({ commit }, { mode, settings }) => {
  if (!settings) {
    return;
  }

  if (mode === themeMode.LIGHT) {
    await updateLightModeSettings(settings);
    return;
  }
  await updateDarkModeSettings(settings);
}

const showToast = ({ state, commit }, message) => {
  if (state.toast.show) commit('HIDE_TOAST')

  setTimeout(() => {
    commit('SHOW_TOAST', {
      color: 'component',
      message,
      timeout: 3000
    })
  })
}

const showError = ({ state, commit }, { message = i18n.t('$messages.somethingWentWrong') }) => {
  if (state.toast.show) commit('HIDE_TOAST')

  setTimeout(() => {
    commit('SHOW_TOAST', {
      color: 'error',
      message,
      timeout: 3000
    })
  })
}

const showSuccess = ({ state, commit }, message) => {
  if (state.toast.show) commit('HIDE_TOAST')

  setTimeout(() => {
    commit('SHOW_TOAST', {
      color: 'success',
      message,
      timeout: 3000
    })
  })
}

const showLoader = ({ state, commit }, payload = { message: '' }) => {
  if (state.loader.show) commit('HIDE_LOADER')

  setTimeout(() => {
    commit('SHOW_LOADER', payload)
  })
}

const hideLoader = ({ commit }, payload = { message: '' }) => {
  setTimeout(() => {
    commit('HIDE_LOADER', payload)
  })
}

export default {
  init,
  saveModeSettings,
  showToast,
  showError,
  showLoader,
  showSuccess,
  hideLoader,
}
