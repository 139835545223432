
<v-footer v-bind="$attrs" v-on="$listeners" color="components" class="footer">
  <slot>
    <div class="overline footer__container">
      <div v-if="$vuetify.breakpoint.smAndUp" class="flex-wrap justify-start d-flex align-content-end">
        <p>{{ $t('$common.copyright') }}</p>
      </div>
     <div class="flex-wrap justify-end flex-auto d-flex">
       <span v-if="$vuetify.breakpoint.xsOnly" class="ototax-copyright">{{ $t('$common.copyright') }}</span>
       <a href="https://ergoproxy.pl" target="_blank" class="footer__link">
         <p class="mb-0">Powered by Ergo Proxy</p>
         <v-img class="ergo-proxy-logo" :src="logoImage" alt="Ergo Proxy company logo" />
       </a>
     </div>
    </div>
  </slot>
</v-footer>
