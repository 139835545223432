
<v-menu
  offset-y
  left
  transition="slide-y-transition"
>
  <template v-slot:activator="{ on }">
    <v-btn color="primary" class="mr-2" v-on="on" small>{{ `${formattedBalance} ${currency}` }}</v-btn>
  </template>
</v-menu>
