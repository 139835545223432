
<v-app :style="{ background: $vuetify.theme.themes[mode].background }">
  <!-- Layout component -->
  <template v-if="isRouterLoaded">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </template>

  <notifications>
    <template v-slot:body="{ item, close }">
      <notification-alert @click="handleNotificationClick(item.data.notificationId)">
        <h3 class="mb-1 line-clamp break-word">{{ item.title }}</h3>
        <div class="line-clamp">
          <p v-html="item.text"></p>
        </div>
      </notification-alert>
    </template>
  </notifications>

  <v-snackbar
    v-model="toast.show"
    v-bind="toast.props"
    :data-cy="`alert-${toast.color}`"
    :timeout="toast.timeout"
    :dark="mode === themeMode.DARK"
    :color="toast.color"
    top
  >
    {{ toast.message }}
  </v-snackbar>
  <v-overlay :value="loader.show" z-index="8">
    <div class="d-flex flex-column align-center justify-center">
      <v-progress-circular
        indeterminate
        :size="loaderSpinnerSize"
      />
      <v-subheader
        class="subtitle-1"
        v-if="loader.message"
      >
        {{ loader.message }}
      </v-subheader>
    </div>
  </v-overlay>

  <statement-dialog
    v-if="statement"
    :form="statement"
    :handler="handleSubmitClick"
    :visible.sync="statementVisible"
  />
</v-app>
