
<v-navigation-drawer
  data-cy="app-sidebar"
  :value="collapsed"
  @input="handleSidebarCollapse"
  app
  floating
  class="elevation-1"
  :right="$vuetify.rtl"
  :color="currentTheme.components"
  :light="mode === themeMode.LIGHT"
  :dark="mode === themeMode.DARK"
>
  <!-- Navigation menu info -->
  <template v-slot:prepend>
    <div class="pa-2">
      <router-link :to="{ name: 'dashboard' }">
        <v-img
          max-height="150"
          max-width="400"
          class="my-4 mx-auto"
          contain
          :src="image"
        />
      </router-link>
    </div>
  </template>

  <toolbar-theme-mode
    v-if="!$vuetify.breakpoint.smAndUp"
    color="primary"
    class="pa-2"
  />

  <!-- Navigation menu -->
  <nav-menu :menu="menuItems" />

  <!-- Navigation menu footer -->
  <template v-slot:append>
    <!-- Footer navigation links -->
    <div class="pa-1 text-center">
      <v-btn
        v-for="(item, index) in footerItems"
        :key="index"
        :href="item.href"
        :target="item.target"
        small
        text
      >
        {{ item.key ? $t(item.key) : item.text }}
      </v-btn>
    </div>
    <div class="pa-2 pt-1 text-center" v-if="!$vuetify.breakpoint.smAndUp">
      <v-btn
        @click="handleLogout"
        color="primary"
        small
      >
        {{ $t('$menu.logout') }}
      </v-btn>
    </div>
    <div class="pa-2 pt-1 text-center">
      <ValidationObserver ref="vatObserver" slim>
        <ValidationProvider name="nip" rules="numeric|nip|required" v-slot="{ errors }">
          <v-text-field
            v-model="form.nip"
            class="mb-2"
            type="text"
            label="NIP"
            :error-messages="errors[0]"
            name="nip"
          />
        </ValidationProvider>
      </ValidationObserver>
      <v-btn
        class="check-vat-button"
        block
        color="primary"
        @click="checkVat"
      >
        {{ $t('$menu.checkVat') }}
      </v-btn>
    </div>
  </template>
</v-navigation-drawer>
