import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {};

const i18n = new VueI18n({
  messages,
  locale: localStorage.getItem('locale') || 'pl',
  silentTranslationWarn: import.meta.env.NODE_ENV === 'production',
});

export const availableLocales = [
  {
    code: 'en',
    flag: 'us',
    label: 'English',
  },
  {
    code: 'pl',
    flag: 'pl',
    label: 'Polski',
  },
]

export default i18n
