import Vue from 'vue';
import App from './App.vue';
import axios from "axios";
import store from './store';
import router from './router';

// PLUGINS
import vuetify from './plugins/vuetify';
import i18n from './plugins/vue-i18n';
import './plugins/vue-shortkey';
import './plugins/vue-head';
import './plugins/vue-gtag';
import './plugins/apexcharts';
import './plugins/animate';
import './plugins/clipboard';
import Sortable from 'sortablejs'
import Notifications from 'vue-notification'
import Confirm from './plugins/confirm';
import ScrollTo from '@/plugins/scroll-to';
import { configure, ValidationProvider, ValidationObserver, extend } from 'vee-validate/dist/vee-validate.esm.js';
import * as rules from 'vee-validate/dist/rules';

import * as Sentry from '@sentry/vue';
import errorHandlingPlugin from './plugins/error-handling';
import security from './plugins/security';
import VueTelInputVuetify from 'vue-tel-input-vuetify';

// STYLES
// Main Theme SCSS
import './assets/scss/main.scss';

// Animation library - https://animate.style/
import 'animate.css/animate.min.css';

Vue.use(ScrollTo);
// Set $confirm dialog
Vue.use(Confirm, { vuetify });

Vue.use(VueTelInputVuetify, {
  vuetify,
});

// Set notifications component
Vue.use(Notifications);

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false;

// Setup axios
axios.defaults.baseURL = import.meta.env.VITE_API_HOST;

Vue.use(errorHandlingPlugin, {
  i18n,
  axios,
  debug: true,
  displayError: async ({ errorMessage }) => {
    await store.dispatch('app/showError', { message: errorMessage })
  },
});


// Init security
security.init({ store, router });

// Set global directives
Vue.directive('sortableDataTable', {
  bind (el, binding, vnode) {
    if (!binding.value) return;

    const options = {
      animation: 150,
      onUpdate: function (event) {
        vnode.child.$emit('sorted', event)
      }
    }
    Sortable.create(el.getElementsByTagName('tbody')[0], options)
  }
})

// Set global components
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ConfirmModal', () => import('./components/common/Modal/ConfirmModal.vue'));
Vue.component('VPage', () => import('./components/common/VPage.vue'));
Vue.component('VDatagrid', () => import('./components/common/Datagrid/VDatagrid.vue'));
Vue.component('VPanel', () => import('./components/common/Panel/VPanel.vue'));
Vue.component('VPanelHeader', () => import('./components/common/Panel/VPanelHeader.vue'));

// Set vee-validate messages
configure({
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`$fields.${field}`);
    values.target = i18n.t(`$fields.${values.target}`);

    return i18n.t(`validations.messages.${values._rule_}`, values);
  }
});

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

extend('nip', value => {
  let nipWithoutDashes = value.replace(/-/g, "");
  let reg = /^[0-9]{10}$/;
  if (reg.test(nipWithoutDashes) === false) {
    return false;
  } else {
    let digits = ("" + nipWithoutDashes).split("");
    let checksum = (6 * parseInt(digits[0]) + 5 * parseInt(digits[1]) + 7 * parseInt(digits[2]) + 2 * parseInt(digits[3]) + 3 * parseInt(digits[4]) + 4 * parseInt(digits[5]) + 5 * parseInt(digits[6]) + 6 * parseInt(digits[7]) + 7 * parseInt(digits[8])) % 11;

    return (parseInt(digits[9]) === checksum);
  }
});

extend('regon', value => {
  const reg = /^[0-9]{9}$/;
  if(!reg.test(value))
    return false;
  else
  {
    const digits = ("" + value).split("");
    let checksum = (8*parseInt(digits[0]) + 9*parseInt(digits[1]) + 2*parseInt(digits[2]) + 3*parseInt(digits[3]) + 4*parseInt(digits[4]) + 5*parseInt(digits[5]) + 6*parseInt(digits[6]) + 7*parseInt(digits[7]))%11;
    if(checksum === 10)
      checksum = 0;

    return (parseInt(digits[8])===checksum);
  }
});

Sentry.init({
  Vue,
  dsn: "https://9dcce6572c404a3f95e15b94974c9b93@o4504996873961472.ingest.sentry.io/4504996999987200",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["ototax.cloud", /^\//],
    }),
    new Sentry.Replay()
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  enabled: import.meta.env.NODE_ENV !== 'development'
});


export default new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
