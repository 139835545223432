<template>
  <div class="auth-layout layout-content ma-auto w-full px-1 px-md-2 px-lg-0">
    <v-img
      max-height="150"
      max-width="400"
      class="my-4 mx-auto"
      :src="logoImagePath"
    />
    <transition name="fade" mode="out-in" appear>
      <router-view :key="$route.fullPath"/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
  computed: {
    logoImagePath() {
      return `/images/logo/${import.meta.env.VITE_LOGO_COLOR}`;
    },
  },
};
</script>

<style scoped lang="scss">
  .layout-content {
    max-width: 650px;
  }
</style>
