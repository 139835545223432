
<div>
  <v-btn
    ref="button"
    class="drawer-button"
    color="primary"
    dark
    @click="visible = true"
  >
    <v-icon class="fa-spin">mdi-cog-outline</v-icon>
  </v-btn>

  <v-navigation-drawer
    v-model="visible"
    color="components"
    fixed
    right
    hide-overlay
    temporary
    width="310"
  >
    <div class="d-flex align-center pa-2">
      <div class="title">{{ $t('$customizationMenu.title') }}</div>
      <v-spacer></v-spacer>
      <v-btn icon @click="visible = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-divider></v-divider>

    <v-tabs
      v-model="currentTab"
      show-arrows
    >
      <v-tab v-for="({ title, mode }, index) in themeTabs" :key="index" :href="`#${mode}`">
        {{ title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab" class="pa-2">
      <v-tab-item
        v-for="({ colors, mode }, index) in themeTabs"
        :key="index"
        :value="mode"
        eager
      >
        <div class="customization-menu__actions actions py-2 text-center">
          <v-btn
            color="primary"
            @click="handleSaveSettingsClick({ mode, themeSettings: theme[mode] })"
          >
            {{ $t('$customizationMenu.saveSettings') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="restoreDefaultTheme(
              {
                mode,
                themeSettings: defaultThemeSettings[mode],
                successMessage: $t('$messages.$customizationMenu.settingsRestoredSuccessfully')
              }
            )"
          >
            {{ $t('$customizationMenu.restoreDefaultTheme') }}
          </v-btn>
        </div>

        <template v-for="color in colors">
          <customization-menu-color
            v-if="initialized"
            :key="color"
            :mode="mode"
            :theme="theme"
            :color="color"
          />
        </template>
      </v-tab-item>
    </v-tabs-items>
  </v-navigation-drawer>
</div>
