
<v-card class="notifications-list" color="components">
  <v-subheader class="pa-2 font-weight-bold">{{ $t('$menu.$myNotifications.title') }}</v-subheader>
  <v-list
    v-if="notifications.length"
    three-line
    dense
    max-width="400"
    color="components"
  >
    <div v-for="(item, index) in notifications" :key="index">
      <v-divider v-if="index > 0 && index < notifications.length" inset></v-divider>
      <v-list-item class="d-flex flex-wrap" @click="handleNotificationClick(item.id)">
        <v-list-item-avatar size="24" :color="item.color" v-if="$vuetify.breakpoint.smAndUp">
          <v-icon
            :dark="themeMode === MODE.DARK"
            size="24"
          >
            mdi-email-open-outline
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-truncate subtitle-1" v-text="item.subject"></v-list-item-title>
          <v-list-item-subtitle class="text-truncate subtitle-2" v-html="formatContent(item.content)"></v-list-item-subtitle>
          <v-list-item-subtitle class="caption">

            {{ format(parseISO(item.createdAt.date), 'dd/MM/yyyy HH:mm') }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list>
  <v-card-text v-else>
    <p>{{ $t('$menu.$myNotifications.$dropdown.currentlyNoNewNotifications') }}</p>
  </v-card-text>

  <v-card-actions class="justify-center py-2">
    <v-btn small @click="seeAllNotificationsClick">{{ $t('$menu.$myNotifications.$dropdown.seeAll') }}</v-btn>
  </v-card-actions>
</v-card>
